import React from 'react';

import { Role } from '../config/role';

// const CodeEditors = React.lazy(() => import("../views/editors/code-editors/CodeEditors"));
// const TextEditors = React.lazy(() => import("../views/editors/text-editors/TextEditors"));

// const Invoice = React.lazy(() => import("../../theme/views/apps/invoicing/Invoice"));

// const AdvancedForms = React.lazy(() => import("../views/forms/advanced-forms/AdvancedForms"));
// const BasicForms = React.lazy(() => import("../views/forms/basic-forms/BasicForms"));
// const ValidationForms = React.lazy(() => import("../views/forms/validation-forms/ValidationForms"));
// const GoogleMaps = React.lazy(() => import("../views/google-maps/GoogleMaps"));
// const Toaster = React.lazy(() => import("../views/notifications/toaster/Toaster"));
// const Calendar = React.lazy(() => import("../views/plugins/calendar/Calendar"));
// const Draggable = React.lazy(() => import("../views/plugins/draggable/Draggable"));
// const Spinners = React.lazy(() => import("../views/plugins/spinners/Spinners"));
// const AdvancedTables = React.lazy(() => import("../views/tables/advanced-tables/AdvancedTables"));
// const Tables = React.lazy(() => import("../views/tables/tables/Tables"));
//const LoadingButtons = React.lazy(() => import('./views/buttons/loading-buttons'));

// const Breadcrumbs = React.lazy(() => import("../views/base/breadcrumbs/Breadcrumbs"));
// const Cards = React.lazy(() => import("../views/base/cards/Cards"));
// const Carousels = React.lazy(() => import("../views/base/carousels/Carousels"));
// const Collapses = React.lazy(() => import("../views/base/collapses/Collapses"));

// const Jumbotrons = React.lazy(() => import("../views/base/jumbotrons/Jumbotrons"));
// const ListGroups = React.lazy(() => import("../views/base/list-groups/ListGroups"));
// const Navbars = React.lazy(() => import("../views/base/navbars/Navbars"));
// const Navs = React.lazy(() => import("../views/base/navs/Navs"));
// const Paginations = React.lazy(() => import("../views/base/paginations/Pagnations"));
// const Popovers = React.lazy(() => import("../views/base/popovers/Popovers"));
// const ProgressBar = React.lazy(() => import("../views/base/progress-bar/ProgressBar"));
// const Switches = React.lazy(() => import("../views/base/switches/Switches"));

// const Tabs = React.lazy(() => import("../views/base/tabs/Tabs"));
// const Tooltips = React.lazy(() => import("../views/base/tooltips/Tooltips"));
// const BrandButtons = React.lazy(() => import("../views/buttons/brand-buttons/BrandButtons"));
// const ButtonDropdowns = React.lazy(() => import("../views/buttons/button-dropdowns/ButtonDropdowns"));
// const ButtonGroups = React.lazy(() => import("../views/buttons/button-groups/ButtonGroups"));
// const Buttons = React.lazy(() => import("../views/buttons/buttons/Buttons"));
// const Charts = React.lazy(() => import("../views/charts/Charts"));
const Dashboard = React.lazy(() => import('../views/dashboard/Dashboard'));
// const TodayView = React.lazy(() => import('../views/dashboard/TodayView'));
const StreamData = React.lazy(() => import('../views/stream/StreamData'));
// orders
const Orders = React.lazy(() => import('../views/orders/Orders'));
const SearchOrders = React.lazy(() => import('../views/orders/SearchOrders'));
const Order = React.lazy(() => import('../views/orders/Order'));
const UncapturedOrders = React.lazy(() => import('../views/orders/UncapturedOrders'));
const GiftCards = React.lazy(() => import('../views/orders/GiftCards'));
// warehouse
const WarehouseSummary = React.lazy(() => import('../views/warehouse/Summary'));
// items
const SearchItem = React.lazy(() => import('../views/items/SearchItem'));
const ItemStatus = React.lazy(() => import('../views/items/ItemStatus'));
const Configurables = React.lazy(() => import('../views/items/Configurables'));
const Prices = React.lazy(() => import('../views/prices/Prices'));
// feeds
const FeedListView = React.lazy(() => import('../views/feeds/FeedListView'));
const FeedView = React.lazy(() => import('../views/feeds/FeedView'));
const FeedIncludeView = React.lazy(() => import('../views/feeds/FeedIncludeView'));
const FeedItemListView = React.lazy(() => import('../views/feeds/FeedItemListView'));
const FeedItemView = React.lazy(() => import('../views/feeds/FeedItemView'));
const FeedHeaderView = React.lazy(() => import('../views/feeds/FeedHeaderView'));
const FeedMapView = React.lazy(() => import('../views/feeds/FeedMapView'));
const FeedPromotionView = React.lazy(() => import('../views/feeds/FeedPromotionView'));
const FeedPromotionItemsView = React.lazy(() => import('../views/feeds/FeedPromotionItemsView'));
const FeedReportView = React.lazy(() => import('../views/feeds/FeedReportView'));
// const FeedCreate = React.lazy(() => import('../views/feeds/FeedCreate'));
// promotions
const SearchPromotions = React.lazy(() => import('../views/promotions/SearchPromotions'));
const ViewPromotion = React.lazy(() => import('../views/promotions/ViewPromotion'));

// reports
const ViewReports = React.lazy(() => import('../views/reports/ViewReports'));

// customers
const BeansHistory = React.lazy(() => import('../views/customers/BeansHistory'));

// Users
const Users = React.lazy(() => import("../views/users/Users"));
const User = React.lazy(() => import("../views/users/User"));

// Zendesk ticket archive
const ArchiveSearch = React.lazy(() => import("../views/zendesk/ArchiveSearch"));
const ArchiveTicket = React.lazy(() => import('../views/zendesk/TicketView'));
//

const ChannelsView = React.lazy(() => import('../views/channels/ChannelsView'));
const ChannelView = React.lazy(() => import('../views/channels/ChannelView'));

const ItemInsightsView = React.lazy(() => import('../views/items/InsightsView'));

// const CoreUIIcons = React.lazy(() => import("../views/icons/coreui-icons/CoreUIIcons"));
// const Flags = React.lazy(() => import("../views/icons/flags/Flags"));
// const Brands = React.lazy(() => import("../views/icons/brands/Brands"));
// const Alerts = React.lazy(() => import("../views/notifications/alerts/Alerts"));
// const Badges = React.lazy(() => import("../views/notifications/badges/Badges"));
// const Modals = React.lazy(() => import("../views/notifications/modals/Modals"));
// const Colors = React.lazy(() => import("../views/theme/colors/Colors"));
// const Typography = React.lazy(() => import("../views/theme/typography/Typography"));
// const Widgets = React.lazy(() => import("../views/widgets/Widgets"));


const routes = [
	{ path: '/', exact: true, name: 'Home', component: Dashboard, roles: [Role.Owner, Role.Admin, Role.Marketing, Role.Sales] },
	// dashboard
	{ path: '/dashboard', name: 'Dashboard', component: Dashboard, roles: [Role.Owner, Role.Admin, Role.Marketing, Role.Sales] },
	// { path: '/today', name: 'Today', component: TodayView, roles: [Role.Owner, Role.Admin, Role.Marketing, Role.Sales] },
	// live
	{ path: '/live', name: 'Live', component: StreamData, roles: [Role.Owner, Role.Admin] },
	//orders
	{ path: '/orders/list', name: 'Orders', component: Orders, roles: [Role.Owner, Role.Admin, Role.Sales] },
	{ path: '/orders/search', name: 'Advanced Search Orders', component: SearchOrders, roles: [Role.Owner, Role.Admin, Role.Sales, Role.Marketing, Role.Backoffice, Role.External] },
	{ path: '/orders/gift_cards', name: 'Gift Cards', component: GiftCards, roles: [Role.Owner, Role.Admin, Role.Sales, Role.Marketing, Role.Backoffice] },
	{ path: '/orders/:order_number/view', name: 'Order', component: Order, roles: [Role.Owner, Role.Admin, Role.Sales, Role.Marketing, Role.Backoffice, Role.External] },
	{ path: '/uncaptured/:store_name', name: 'Uncaptured Orders', component: UncapturedOrders, roles: [Role.Owner, Role.Admin, Role.Sales, Role.Marketing, Role.Backoffice] },
	// warehouse
	{ path: '/warehouse/summary', name: 'Warehouse', component: WarehouseSummary, roles: [Role.Owner, Role.Admin, Role.Sales, Role.Backoffice] },

	// items
	{ path: '/items/view/:sku', name: 'Items', component: SearchItem, roles: [Role.Owner, Role.Admin, Role.Sales, Role.Marketing, Role.Backoffice] },
	{ path: '/items/status', name: 'Items Store Status', component: ItemStatus, roles: [Role.Owner, Role.Admin, Role.Sales, Role.Marketing, Role.Backoffice] },
	{ path: '/items/configurables', name: 'Configurables', component: Configurables, roles: [Role.Owner, Role.Admin, Role.Sales, Role.Marketing, Role.Backoffice] },
	{ path: '/items/insights/:sku', name: 'Insights', component: ItemInsightsView, roles: [Role.Owner, Role.Admin, Role.Sales, Role.Marketing] },

	// prices
	{ path: '/prices', name: 'Prices', component: Prices, roles: [Role.Owner] },

	// feeds
	{ path: '/feed/list', name: 'Feeds', component: FeedListView, roles: [Role.Owner, Role.Admin, Role.Marketing] },
	// { path: '/feed/new', name: 'Create Feed', component: FeedCreate, roles: [Role.Owner, Role.Admin, Role.Marketing] },

	{ path: '/feed/:feed_id/view', name: 'Feed', component: FeedView, roles: [Role.Owner, Role.Admin, Role.Marketing] },
	{ path: '/feed/:feed_id/promotions/view', name: 'Promotions', component: FeedPromotionView, roles: [Role.Owner, Role.Admin, Role.Marketing] },
	{ path: '/feed/:feed_id/promotion/:promotion_id/items', name: 'Promotion Items', component: FeedPromotionItemsView, roles: [Role.Owner, Role.Admin] },
	{ path: '/feed/:feed_id/headers/view', name: 'Headers', component: FeedHeaderView, roles: [Role.Owner, Role.Admin] },
	{ path: '/feed/:feed_id/maps/view', name: 'Maps', component: FeedMapView, roles: [Role.Owner, Role.Admin] },
	{ path: '/feed/:feed_id/includes/view', name: 'Feed Item Includes', component: FeedIncludeView, roles: [Role.Owner, Role.Admin] },
	{ path: '/feed/:feed_id/items/list', name: 'Feed Items', component: FeedItemListView, roles: [Role.Owner, Role.Admin, Role.Marketing] },
	{ path: '/feed/:feed_id/items/:sku/view', name: 'Feed Item', component: FeedItemView, roles: [Role.Owner, Role.Admin, Role.Marketing] },
	{ path: '/feed/:feed_id/report/view', name: 'Feed Item', component: FeedReportView, roles: [Role.Owner, Role.Admin, Role.Marketing] },

	// promotions
	{ path: '/promotions/search', exact: true, name: 'Search Promotions', component: SearchPromotions, roles: [Role.Owner, Role.Admin, Role.Sales] },
	{ path: '/promotions/:rule_name/view', name: 'View Promotion', component: ViewPromotion, roles: [Role.Owner, Role.Admin, Role.Sales] },
	// reports
	{ path: '/reports/compare', exact: true, name: 'Reports', component: ViewReports, roles: [Role.Owner, Role.Admin] },

	// customers
	{ path: '/customers/beans', exact: true, name: 'Customers', component: BeansHistory, roles: [Role.Owner, Role.Admin, Role.Backoffice, Role.Sales] },

	// users
	{ path: '/users/list', exact: true, name: 'Users', component: Users, roles: [Role.Owner] },
	{ path: '/users/new', exact: true, name: 'New User', component: User, roles: [Role.Owner] },
	{ path: '/users/:user_id/view', exact: true, name: 'View User', component: User, roles: [Role.Owner] },

	// zendesk archive
	{ path: '/zendesk/archive/search', exact: true, name: 'Search Archive', component: ArchiveSearch, roles: [Role.Owner, Role.IT, Role.Admin, Role.Backoffice] },
	{ path: '/zendesk/archive/ticket/:company/:id', exact: true, name: 'View Ticket', component: ArchiveTicket, roles: [Role.Owner, Role.IT, Role.Admin, Role.Backoffice] },

	// channels
	{ path: '/channels', exact: true, name: 'Channels', component: ChannelsView, roles: [Role.Owner, Role.Admin, Role.Marketing] },
	{ path: '/channels/:channel', exact: true, name: 'Channel', component: ChannelView, roles: [Role.Owner, Role.Admin, Role.Marketing] },
];

export default routes;
