const initialState = {
	data: [],

	error: '',
	errorStatusCode: null,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case 'FETCH_SUCCESS':
			state = {
				...state,
				error: '',
				errorStatusCode: null,
				data: action.payload,
			};
			break;
		case 'FETCH_FAILURE':
			state = {
				...state,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;

		default:
			state = { ...state };
			break;
	}
	return state;
};

export default reducer;
