const initialState = {
  item: {},
  product_status: [],
  configurables: {},

  error: "",
  errorStatusCode: null,

  token: localStorage.getItem("token"),
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
		case 'USER_UNAUTHORIZED':
			state = {
				...state,
				loggingIn: false,
				error: action.payload.data.error,
				errorStatusCode: action.payload.status,
				fetchingPriceItem: false,
			};
			break;
		// item
		case 'FETCH_ITEM_SUCCESS':
			state = {
				...state,
				item: action.payload,
				error: ''
			};
			break;
		case 'FETCH_ITEM_FAILURE':
			state = {
				...state,
				item: {},
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;
		// store item status
		case 'FETCH_STORE_ITEM_STATUS_SUCCESS':
			state = {
				...state,
				product_status: action.payload,
			};
			break;
		case 'FETCH_STORE_ITEM_STATUS_FAILURE':
			state = {
				...state,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;
		// configurables
		case 'FETCH_CONFIGURABLES_SUCCESS':
			state = {
				...state,
				configurables: action.payload,
			};
			break;
		case 'FETCH_CONFIGURABLES_FAILURE':
			state = {
				...state,
				error: action.payload.data.error.message,
				errorStatusCode: action.payload.status,
			};
			break;

		default:
			state = { ...state };
			break;
  }
  return state;
};

export default reducer;
